import bgHome1 from "./bg-home-1.jpg";
import bgHome2 from "./bg-home-2.jpg";
import bgHome3 from "./bg-home-3.jpg";
import bgAbout1 from "./img-about-1.jpg";
import bgMAbout from "./img-about-mobile-1.jpg";
import bgMHome1 from "./img-home-mobile-1.jpg";
import bgMHome2 from "./img-home-mobile-2.jpg";
import bgMHome3 from "./img-home-mobile-3.jpg";
import leadingTransform from "./leading-digital-transformation.jpg";
import testimoni1 from "./testimoni-1.png";
import logo from "./logo.png";
import logoTitle from "./logo-title.png";
import imgPorto1 from "./img-porto-1.png";
import profile1 from "./person_1.jpg";
import profile2 from "./person_2.jpg";
import profile3 from "./person_3.jpg";
import profile4 from "./person_4.jpg";
import profile5 from "./person_5.png";
import profile6 from "./person_6.jpg";
import profile7 from "./person_7.jpg";
import imgNews from "./img-news.webp";
import imgFocus from "./img-focus.jpg";
import imgMissions from "./img-missions.jpg";
import imgApproach from "./img-approach.jpg";

const img = {
  bgHome1,
  bgHome2,
  bgHome3,
  bgAbout1,
  bgMAbout,
  bgMHome1,
  bgMHome2,
  bgMHome3,
  leadingTransform,
  testimoni1,
  logo,
  logoTitle,
  imgPorto1,
  profile1,
  profile2,
  profile3,
  profile4,
  profile5,
  profile6,
  profile7,
  imgNews,
  imgFocus,
  imgMissions,
  imgApproach,
};

export { img };
