import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { img } from "../assets/images";

const Navbar = () => {
  let Links = [
    { name: "Manifesto", link: "/manifesto" },
    // { name: "Portofolio", link: "/portofolio" },
    // { name: "Reports", link: "/reports" },
    // { name: "News", link: "/news" },
    { name: "Contacts", link: "/contacts" },
  ];

  let [open, setOpen] = useState(false);

  return (
    <div className="shadow-md w-screen fixed top-0 left-0 z-10">
      <div className="md:flex items-center md:justify-between bg-white py-5 lg:px-20 px-7">
        <div
          onClick={() => setOpen(!open)}
          className="font-bold text-2xl cursor-pointer flex items-center text-black">
          <NavLink className={"flex flex-row items-center gap-5"} to={"/"}>
            <img
              className="w-24 md:hidden"
              src={img.logoTitle}
              alt="logo title"
            />
            <img
              className="w-8 hidden md:block lg:w-12"
              src={img.logo}
              alt="logo"
            />
            <h1 className="hidden md:block">Qubicle One</h1>
          </NavLink>
        </div>

        <div
          onClick={() => setOpen(!open)}
          className="text-3xl absolute right-8 top-6 cursor-pointer lg:hidden">
          <ion-icon name={open ? "close" : "menu"}></ion-icon>
        </div>

        <ul
          className={`lg:flex lg:items-center lg:pb-0 pb-12 absolute lg:static bg-white lg:z-auto z-[-1] left-0 w-full lg:w-auto lg:pl-0 pl-9 transition-all duration-500 ease-in ${
            open ? "top-16" : "top-[-490px]"
          }`}>
          {Links.map((link) => (
            <li
              onClick={() => setOpen(!open)}
              key={link.name}
              className="lg:ml-8 text-lg lg:my-0 my-7 active:font-bold">
              <NavLink to={link.link} className="aria-[current=page]:font-bold">
                <div className="text-black hover:font-bold duration-100">
                  {link.name}
                </div>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
