import { lazy } from "react";

const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
// const Portofolio = lazy(() => import("./pages/Portofolio"));c
// const Reports = lazy(() => import("./pages/Reports"));
// const News = lazy(() => import("./pages/News"));
const Contacts = lazy(() => import("./pages/Contacts"));

const routes = [
  // if not found redirect to homepage or another page
  {
    path: "*",
    component: Home, // change this element to 404 page for the example
  },
  {
    path: "/",
    component: Home,
  },
  {
    path: "/manifesto",
    component: About,
  },
  // {
  //   path: "/portofolio",
  //   component: Portofolio,
  // },
  // {
  //   path: "/reports",
  //   component: Reports,
  // },
  // {
  //   path: "/news",
  //   component: News,
  // },
  {
    path: "/contacts",
    component: Contacts,
  },
];

export default routes;
